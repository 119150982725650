<template>
  <div class="widhtdraw-container fami-container">
    <p class="tip">
      <span>最低提现金额：{{ cash }}元</span>
      <span
        style="margin-left:20px;"
        v-if="isCompany"
      >
        提现周期：{{ cycle }}
      </span>
    </p>
    <p>
      提现金额：
      <el-input
        v-model="money"
        :placeholder="`不能超过${account}元`"
      />
      <!-- <span
        class="tip tipq"
      >*账户已冻结佣金的10%作为保证金，以防发生用户退款。</span> -->
    </p>
    <p>收款账户：{{ bankaccount }}</p>
    <template v-if="isCompany">
      <p>上传发票：</p>
      <el-upload
        list-type="picture-card"
        :action="actionUrl"
        :data="uploadData"
        :headers="headers"
        name="invioce_file"
        drag
        class="upload"
        :file-list="fileList"
        :on-preview="handlePictureCardPreview"
        :before-upload="beforeUpload"
        :on-success="successHandle"
        :on-remove="handleRemove"
        v-if="isPic"
      >
        <template #default>
          <i class="el-icon-picture picture-icon" />
          <p class="text">
            拖动上传
          </p>
          <i class="el-icon-circle-plus add-icon" />
        </template>
      </el-upload>

      <p
        class="tip"
        v-if="isPic && fileList.length<=0"
      >
        支持PDF，OFD格式，最大2M
      </p>

      <div
        class="pdf"
        v-if="!isPic"
      >
        <i
          class="el-icon-close remove-icon"
          @click="removeFile"
        />
        <a
          :href="fileList[0].url"
          target="_blank"
          class="green-btn check-btn "
        >查看发票
        </a>
      </div>
    </template>

    <el-dialog v-model="dialogVisible">
      <img
        width="100%"
        :src="dialogImageUrl"
        alt=""
        class="dialog-image"
      >
    </el-dialog>

    <div class="btns">
      <button
        @click="backgo"
        class="back-btn green-border-btn"
      >
        返回
      </button>
      <button
        @click="handleUpdata"
        class="updata-btn green-btn"
      >
        提交申请
      </button>
    </div>
  </div>
</template>

<script lang='ts'>
import { getToken } from '@/utils/cookies'
import { useStore } from '@/store'
import { handleWithdrawal } from '@/apis/order'
import { IndexMessage } from '@/model/orderModel'
import storage, { StorageType } from '@/utils/storage'
import { addClass, removeClass } from '@/utils'
import { ElMessage } from 'element-plus'
import { AGENTTYPE } from '@/store/modules/user/state'
import {
  computed,
  defineComponent,
  reactive,
  toRefs
} from 'vue'
import { useRouter } from 'vue-router'

interface FileInter{
  code: number
  data: {db_url: string, visit_url: string}
  msg: string
}
interface DataMapInter{
  dialogImageUrl: any
  fileList: IndexMessage<undefined | string>[]
  dbUrl: string
  dialogVisible: boolean
  money: string
  isPic: boolean
  handleUpdata: () => void
  successHandle: (file: FileInter) => void
}
export default defineComponent({
  setup() {
    const router = useRouter()
    const getSignData: () => IndexMessage<undefined | string> = () => {
      let signData = storage.rcGetItem(StorageType.local, 'signData')
      signData = signData ? JSON.parse(signData) : {}
      return signData
    }
    const store = useStore()
    const isCompany = computed(() => {
      return store.state.user.userinfo.is_enterprise === AGENTTYPE.COMPANY
    })
    const account = computed(() => {
      return store.state.user.account
    })
    const cash = computed(() => {
      return store.state.user.userinfo.cash_require
    })

    const cycle = computed(() => {
      const c = store.state.user.userinfo.settlement_cycle
      let b = ''
      switch (c) {
        case 1:
          b = '日'
          break
        case 2:
          b = '周'
          break
        case 3:
          b = '月'
          break
      }
      return b
    })
    const dataMap: DataMapInter = reactive({
      fileList: [],
      dbUrl: '',
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      isPic: true,
      money: '',
      handleUpdata: async() => {
        const { money, dbUrl } = dataMap
        let errerText = ''
        if (Number(money) > account.value) {
          errerText = `当前可提现余额不足${money}`
        }
        if (!money) {
          errerText = '请填写提现金额'
        }
        if (isCompany.value && !dbUrl) {
          errerText = '请上传发票'
        }
        if (errerText) {
          ElMessage.error({
            message: errerText,
            type: 'error',
            onClose: () => {
              errerText = ''
            }
          })
          return
        }
        await handleWithdrawal({
          money: dataMap.money,
          db_url: dataMap.dbUrl,
          oss: 1
        }).then((res) => {
          if (res?.code === 200) {
            ElMessage.success('提交申请成功！')
            router.go(-1)
          } else {
            ElMessage.error(res?.msg)
          }
        }, (error) => {
          ElMessage.error(error)
        })
      },
      successHandle: (file: FileInter) => {
        if (file.code === 200) {
          dataMap.fileList.push({ name: 'file', url: file.data?.visit_url })
          dataMap.dbUrl = file.data.db_url
          addClass(document.querySelector('.el-upload') as HTMLElement, 'hide')
          if (/.pdf\?|.ofd\?/.test(file.data?.visit_url)) {
            dataMap.isPic = false
          } else {
            dataMap.isPic = true
          }
        }
      },
      handleRemove(file: IndexMessage<string>) {
        dataMap.fileList = dataMap.fileList.filter((item) => {
          return item.uid !== file.uid
        })
        removeClass(document.querySelector('.el-upload') as HTMLElement, 'hide')
      },
      handlePictureCardPreview(file: IndexMessage<string>) {
        dataMap.dialogImageUrl = file.url
        dataMap.dialogVisible = true
      },
      removeFile() {
        dataMap.fileList.shift()
        dataMap.isPic = true
      },
      beforeUpload(file: IndexMessage<string | number>) {
        const isFile = /.pdf|.ofd/.test(file.name as string)
        const isLt2M = (file.size as number) / 1024 / 1024 < 2
        if (!isFile) {
          ElMessage.error('只能上传PDF，OFD格式！')
        }
        if (!isLt2M) {
          ElMessage.error('文件大小不能超过 2MB！')
        }
        return isFile && isLt2M
      },
      backgo() {
        router.go(-1)
      }
    })
    const headers = computed(() => {
      return {
        'Monimaster-Token': getToken()
      }
    })
    const uploadData = computed(() => {
      return getSignData()
    })
    const bankaccount = computed(() => {
      return store.state.user.bankaccount
    })

    const actionUrl = computed(() => {
      return process.env.VUE_APP_BASE_API + '/agent/file/upload'
    })
    return {
      ...toRefs(dataMap),
      headers,
      account,
      uploadData,
      actionUrl,
      bankaccount,
      isCompany,
      cycle,
      cash
    }
  }

})
</script>

<style lang="scss" scoped>
.widhtdraw-container{
  &>p{
    margin-bottom: 20px;
    font-size: 18px;
    ::v-deep(.el-input){
      margin-top:10px;
    }
  }
  .tip{
    font-size: 14px;
    color: #666;
    margin-top:14px;
  }
  .tipq{
    margin-left: 20px;
  }
}
.check-btn{
  display: block;
}
.el-input--medium{
  max-width: 314px;
}
.upload{
  position: relative;
  display: flex;
  ::v-deep(.hide){
    display: none !important;
  }
  ::v-deep(.el-upload--picture-card){
    width: 250px;
    height: 158px;
    background:#fff;
    border: none;
    display: flex;
    .el-upload-dragger{
      width: 250px;
      height: 158px;
      border: 1px dashed $main;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .hide{
        display: none;
      }
      .text{
        height: 20px;
        color: #666;
        font-size: 14px;
        line-height: 1;
        margin-top: 10px;
      }
    }
    .add-icon{
      position: absolute;
      right: 10px;
      top: 10px;
      color: #BDBDBD;
    }
    .picture-icon{
      font-size: 50px ;
      color: #BDBDBD;
    }
  }
  ::v-deep(.el-upload-list__item){
    height: 158px;
    display: flex;
    align-items: center;
    border: 1px dashed $main;
    .el-upload-list__item-thumbnail{
      max-width: 100%;
      height: auto;
    }
  }

}
.pdf{
  position: relative;
  max-width:300px;
}
.remove-icon{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  display:block;
  background:$main;
  color: #fff;
  line-height: 20px;
  text-align: center;
  right: 10px;
  top: 0;
  cursor: pointer;
  bottom: 0;
  margin: auto;
}
.dialog-image{
  width: 100% !important;
}
.btns{
  margin-top: 50px;
}
.back-btn{
  display: inline-block;
  width: 137px;
  margin-right: 20px;
}
.updata-btn{
  border:none;
  width: 137px;
}
@media (max-width: 767px){
  .widhtdraw-container{
    &>p{
      ::v-deep(.el-input){
        margin-top:10px;
      }
    }
    .btns{
      display: flex;
      .updata-btn{
        padding:0 20px;
      }
    }
    .tipq{
      margin:10px 0 0 0;
      font-size: 12px;
    }
  }
}
@media (max-width: 350px){
  .back-btn{
    margin-right: 10px;
    width: auto;
    padding:0 18px;
  }
}
</style>
