<template>
  <div
    class="condition-container fami-container"
  >
    <template v-if="status !== -1">
      <p>提现金额：{{ withdrawal }}</p>
      <p>收款账户：{{ accountType }}</p>
      <template v-if="isCompany">
        <p>上传发票： </p>
        <el-image
          :src="url"
          :preview-src-list="srcList"
          v-if="url"
        >
          <template #error>
            <div class="image-slot">
              <i class="el-icon-picture-outline" />
            </div>
          </template>
        </el-image>
        <div
          class="pdf"
          v-else
        >
          <a
            :href="file"
            target="_blank"
            class="green-border-btn check-btn "
          >查看发票
          </a>
        </div>
      </template>
      <p v-if="status !== 5">
        <span>是否审核通过：</span>{{ (status=== 1 || status === 3) ? '是' : '否' }}
      </p>
      <p v-if="remark">
        原因：{{ remark }}
      </p>

      <p v-if="status === 1">
        <span>是否已支付：</span> 是
      </p>

      <div class="btns">
        <router-link
          to="/finace/index"
          class="back-btn green-btn"
        >
          返回
        </router-link>
      </div>
    </template>
  </div>
</template>

<script lang='ts'>
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  toRefs
} from 'vue'
import { useRoute } from 'vue-router'
import { withdrawalDetail } from '@/apis/order'
import requestMiddle, { RequestInter } from '@/utils/request'
import { AGENTTYPE } from '@/store/modules/user/state'
import { useStore } from '@/store'
interface DataMap{
  url: string
  srcList: string[]
  accountType: string
  remark: string
  withdrawal: string
  status: number
  file: string
  getwithdrawalDetail: (option: {id: number | string}) => void
}
export default defineComponent({
  components: {
    // PdfApp
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const dataMap: DataMap = reactive({
      url: '',
      srcList: [],
      accountType: '',
      remark: '',
      withdrawal: '',
      status: -1,
      file: '',
      getwithdrawalDetail: async(option: {id: number | string}) => {
        const datail = await requestMiddle({
          requestMethodName: withdrawalDetail,
          option
        } as unknown as RequestInter)
        try {
          const { withdrawal, status, invoice_file, remark, account_type, self_share_img } = datail
          if (self_share_img) {
            dataMap.url = self_share_img
            dataMap.srcList.push(self_share_img as string)
          } else {
            dataMap.file = invoice_file
          }
          dataMap.remark = remark
          dataMap.withdrawal = withdrawal
          dataMap.status = status
          dataMap.accountType = account_type
        } catch (error) {
          console.log(error)
        }
      }
    })

    onBeforeMount(() => {
      const { query } = route
      dataMap.getwithdrawalDetail({ id: query.id as string })
    })
    const isCompany = computed(() => {
      return store.state.user.userinfo.is_enterprise === AGENTTYPE.COMPANY
    })
    return {
      ...toRefs(dataMap),
      isCompany
    }
  }
})
</script>

<style lang="scss" scoped>
.back-btn{
  display: inline-block;
  width: 137px;
}
.condition-container{
  &>p{
    font-size: 18px;
    margin-bottom: 20px;
  }
  .el-icon-picture-outline{
    font-size: 120px;
    color: #666;
    font-weight: lighter;
  }
  .btns{
    margin-top:50px
  }
  .pdf{
    margin-bottom:20px;
  }
}
.pdf{
  max-width: 300px;
}
.check-btn{
  display: block;
}
</style>
