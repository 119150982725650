import Clipboard from 'clipboard'
import { ElMessage } from 'element-plus'
export const clipboardSuccess = () =>
  ElMessage({
    message: '复制成功',
    type: 'success',
    duration: 1500,
    offset: 300
  })

export const clipboardError = () =>
  ElMessage({
    message: '复制失败',
    type: 'error',
    offset: 300
  })

export const handleClipboard = (text: string, event: MouseEvent) => {
  const clipboard = new Clipboard(event.target as Element, {
    text: () => text
  })
  clipboard.on('success', () => {
    clipboardSuccess()
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    clipboardError()
    clipboard.destroy()
  });
  (clipboard as any).onClick(event)
}
