
import {
  defineComponent,
  computed,
  ref,
  reactive,
  toRefs
} from 'vue'
import { useStore } from '@/store'
// import Listitem from './components/Listitem.vue'
import { AGENTTYPE, Company } from '@/store/modules/user/state'
import { handleClipboard, clipboardSuccess } from '@/utils/clipboard' // use clipboard directly

export default defineComponent({
  // components: {
  //   Listitem
  // },
  setup() {
    const dataMap = reactive({
      clipboardSuccess: clipboardSuccess,
      handleClipboard: handleClipboard,
      copyIcon: 0
    })
    const store = useStore()
    const defaultMsg = ref(
      '名称：深圳麦风科技有限公司\n纳税识别号：914403003425095958\n地址：深圳市南山区粤海街道高新区社区高新南一道006号TCL大厦A810\n开户银行：招商银行深圳宝安支行\n银行账号：755926082410303'
    )
    const company = computed(() => {
      return store.state.user.company
    })
    const isCompany = computed(() => {
      return (store.state.user.company as Company).is_company === AGENTTYPE.COMPANY
    })
    return {
      company,
      defaultMsg,
      isCompany,
      ...toRefs(dataMap)
    }
  }
})
