
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  toRefs
} from 'vue'
import { useRoute } from 'vue-router'
import { withdrawalDetail } from '@/apis/order'
import requestMiddle, { RequestInter } from '@/utils/request'
import { AGENTTYPE } from '@/store/modules/user/state'
import { useStore } from '@/store'
interface DataMap{
  url: string
  srcList: string[]
  accountType: string
  remark: string
  withdrawal: string
  status: number
  file: string
  getwithdrawalDetail: (option: {id: number | string}) => void
}
export default defineComponent({
  components: {
    // PdfApp
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const dataMap: DataMap = reactive({
      url: '',
      srcList: [],
      accountType: '',
      remark: '',
      withdrawal: '',
      status: -1,
      file: '',
      getwithdrawalDetail: async(option: {id: number | string}) => {
        const datail = await requestMiddle({
          requestMethodName: withdrawalDetail,
          option
        } as unknown as RequestInter)
        try {
          const { withdrawal, status, invoice_file, remark, account_type, self_share_img } = datail
          if (self_share_img) {
            dataMap.url = self_share_img
            dataMap.srcList.push(self_share_img as string)
          } else {
            dataMap.file = invoice_file
          }
          dataMap.remark = remark
          dataMap.withdrawal = withdrawal
          dataMap.status = status
          dataMap.accountType = account_type
        } catch (error) {
          console.log(error)
        }
      }
    })

    onBeforeMount(() => {
      const { query } = route
      dataMap.getwithdrawalDetail({ id: query.id as string })
    })
    const isCompany = computed(() => {
      return store.state.user.userinfo.is_enterprise === AGENTTYPE.COMPANY
    })
    return {
      ...toRefs(dataMap),
      isCompany
    }
  }
})
