<template>
  <div class="accountinfo fami-container">
    <div class="infoitem">
      <h3>麦风科技付款账号信息：</h3>
      <ul>
        <li style="padding-right: 20px">
          名称：深圳麦风科技有限公司
          <i
            @click="handleClipboard(defaultMsg, $event)"
            class="el-icon-document-copy copy-icon"
          />
        </li>
        <li>纳税识别号：914403003425095958</li>
        <li>地址：深圳市南山区粤海街道高新区社区高新南一道006号TCL大厦A810</li>
        <li>开户银行：招商银行深圳宝安支行</li>
        <li>银行账号：755926082410303</li>
      </ul>
    </div>
    <div class="infoitem">
      <h3>我的收款账户信息：</h3>
      <ul v-if="isCompany">
        <li>名称：{{ company.company_name }}</li>
        <li>纳税识别号：{{ company.taxpayer_code }}</li>
        <li>地址：{{ company.address }}</li>
        <li>开户银行：{{ company.bank_deposit }}</li>
        <li>银行账号：{{ company.bank_account }}</li>
      </ul>
      <ul v-else>
        <li>名称：{{ company.agent_role }}</li>
        <li>个人账号：{{ company.personal_account }}</li>
        <li>账号类型：{{ company.personal_account_type }}</li>
      </ul>
    </div>
  </div>
</template>

<script lang='ts'>
import {
  defineComponent,
  computed,
  ref,
  reactive,
  toRefs
} from 'vue'
import { useStore } from '@/store'
// import Listitem from './components/Listitem.vue'
import { AGENTTYPE, Company } from '@/store/modules/user/state'
import { handleClipboard, clipboardSuccess } from '@/utils/clipboard' // use clipboard directly

export default defineComponent({
  // components: {
  //   Listitem
  // },
  setup() {
    const dataMap = reactive({
      clipboardSuccess: clipboardSuccess,
      handleClipboard: handleClipboard,
      copyIcon: 0
    })
    const store = useStore()
    const defaultMsg = ref(
      '名称：深圳麦风科技有限公司\n纳税识别号：914403003425095958\n地址：深圳市南山区粤海街道高新区社区高新南一道006号TCL大厦A810\n开户银行：招商银行深圳宝安支行\n银行账号：755926082410303'
    )
    const company = computed(() => {
      return store.state.user.company
    })
    const isCompany = computed(() => {
      return (store.state.user.company as Company).is_company === AGENTTYPE.COMPANY
    })
    return {
      company,
      defaultMsg,
      isCompany,
      ...toRefs(dataMap)
    }
  }
})
</script>

<style lang="scss" scoped>
.accountinfo{
  .infoitem{
    margin-bottom: 60px;
  }
  h3{
    font-size: 18px;
    color: #333;
    margin-bottom: 30px;
  }
  ul{
    list-style: none;
    max-width: 650px;
    padding: 20px;
    border: 1px solid #999;
    line-height: 32px;
    position: relative;
    .copy-icon{
      cursor: pointer;
      font-size: 20px;
      position: absolute;
      right: 20px;
      top:20px;
    }
  }
}
</style>
