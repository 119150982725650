
import { getToken } from '@/utils/cookies'
import { useStore } from '@/store'
import { handleWithdrawal } from '@/apis/order'
import { IndexMessage } from '@/model/orderModel'
import storage, { StorageType } from '@/utils/storage'
import { addClass, removeClass } from '@/utils'
import { ElMessage } from 'element-plus'
import { AGENTTYPE } from '@/store/modules/user/state'
import {
  computed,
  defineComponent,
  reactive,
  toRefs
} from 'vue'
import { useRouter } from 'vue-router'

interface FileInter{
  code: number
  data: {db_url: string, visit_url: string}
  msg: string
}
interface DataMapInter{
  dialogImageUrl: any
  fileList: IndexMessage<undefined | string>[]
  dbUrl: string
  dialogVisible: boolean
  money: string
  isPic: boolean
  handleUpdata: () => void
  successHandle: (file: FileInter) => void
}
export default defineComponent({
  setup() {
    const router = useRouter()
    const getSignData: () => IndexMessage<undefined | string> = () => {
      let signData = storage.rcGetItem(StorageType.local, 'signData')
      signData = signData ? JSON.parse(signData) : {}
      return signData
    }
    const store = useStore()
    const isCompany = computed(() => {
      return store.state.user.userinfo.is_enterprise === AGENTTYPE.COMPANY
    })
    const account = computed(() => {
      return store.state.user.account
    })
    const cash = computed(() => {
      return store.state.user.userinfo.cash_require
    })

    const cycle = computed(() => {
      const c = store.state.user.userinfo.settlement_cycle
      let b = ''
      switch (c) {
        case 1:
          b = '日'
          break
        case 2:
          b = '周'
          break
        case 3:
          b = '月'
          break
      }
      return b
    })
    const dataMap: DataMapInter = reactive({
      fileList: [],
      dbUrl: '',
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      isPic: true,
      money: '',
      handleUpdata: async() => {
        const { money, dbUrl } = dataMap
        let errerText = ''
        if (Number(money) > account.value) {
          errerText = `当前可提现余额不足${money}`
        }
        if (!money) {
          errerText = '请填写提现金额'
        }
        if (isCompany.value && !dbUrl) {
          errerText = '请上传发票'
        }
        if (errerText) {
          ElMessage.error({
            message: errerText,
            type: 'error',
            onClose: () => {
              errerText = ''
            }
          })
          return
        }
        await handleWithdrawal({
          money: dataMap.money,
          db_url: dataMap.dbUrl,
          oss: 1
        }).then((res) => {
          if (res?.code === 200) {
            ElMessage.success('提交申请成功！')
            router.go(-1)
          } else {
            ElMessage.error(res?.msg)
          }
        }, (error) => {
          ElMessage.error(error)
        })
      },
      successHandle: (file: FileInter) => {
        if (file.code === 200) {
          dataMap.fileList.push({ name: 'file', url: file.data?.visit_url })
          dataMap.dbUrl = file.data.db_url
          addClass(document.querySelector('.el-upload') as HTMLElement, 'hide')
          if (/.pdf\?|.ofd\?/.test(file.data?.visit_url)) {
            dataMap.isPic = false
          } else {
            dataMap.isPic = true
          }
        }
      },
      handleRemove(file: IndexMessage<string>) {
        dataMap.fileList = dataMap.fileList.filter((item) => {
          return item.uid !== file.uid
        })
        removeClass(document.querySelector('.el-upload') as HTMLElement, 'hide')
      },
      handlePictureCardPreview(file: IndexMessage<string>) {
        dataMap.dialogImageUrl = file.url
        dataMap.dialogVisible = true
      },
      removeFile() {
        dataMap.fileList.shift()
        dataMap.isPic = true
      },
      beforeUpload(file: IndexMessage<string | number>) {
        const isFile = /.pdf|.ofd/.test(file.name as string)
        const isLt2M = (file.size as number) / 1024 / 1024 < 2
        if (!isFile) {
          ElMessage.error('只能上传PDF，OFD格式！')
        }
        if (!isLt2M) {
          ElMessage.error('文件大小不能超过 2MB！')
        }
        return isFile && isLt2M
      },
      backgo() {
        router.go(-1)
      }
    })
    const headers = computed(() => {
      return {
        'Monimaster-Token': getToken()
      }
    })
    const uploadData = computed(() => {
      return getSignData()
    })
    const bankaccount = computed(() => {
      return store.state.user.bankaccount
    })

    const actionUrl = computed(() => {
      return process.env.VUE_APP_BASE_API + '/agent/file/upload'
    })
    return {
      ...toRefs(dataMap),
      headers,
      account,
      uploadData,
      actionUrl,
      bankaccount,
      isCompany,
      cycle,
      cash
    }
  }

})
